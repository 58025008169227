body {
  --red: #ff3008;
  --grey6: #F2F2F2;
  --grey5: #edeeef;
  --grey4: #BDBDBD;
  --grey3: #828282;
  --grey1: #333333;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

div.MuiContainer-maxWidthLg	{
  max-width: 1120px;
  padding: 0px;
}

button.help-fab {
  position: fixed;
  right: 20px;
  bottom: 20px;
}


/* Shop page styling */
img.thumbnail {
  width: 190px;
  height: 190px;
  border-radius: 100px;
  background: #000;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}
.shop-header h1, .shop-header p {
  text-align: center;
}
div.shop-grid {
  padding-bottom: 60px;
  padding-top: 267px;
}
.shop-grid h2 {
  text-align: center;
  margin-bottom: 60px;
}
div.login form {
  padding: 0px;
  border-radius: 10px;
}
div.artist-card img {
  margin-bottom: 5px;
  max-width: 100%;
  border-radius: 10px;
  aspect-ratio: 1;
}
.artist-card a {
  text-align: left;
  display: block;
  margin-top: 5px;
}
.data-error {
  padding-top: 60px !important;
  max-width: 75% !important;
  display: block !important;
  margin: auto !important;
}
.data-error h1, .data-error p {
  text-align: center;
}
img.cover-image {
  width: 1440px;
  height: 400px;
  width: stretch;
}
div.artist-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media(min-width: 900px) {
  div.shop-grid.scroll {
    padding-top: 560px;
    z-index: 0;
  }
  div.login form {
    border: 1px solid var(--grey4);
    padding: 60px;
  }
  div.shop-grid.final {
    margin-top: 267px;
  }
  div.artist-card {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  div.shop-grid {
    padding-top: 0px;
  }
}
div.shop-grid button {
  font-weight: 400;
  font-size: 14px;
  font-family: Montserrat;
  text-align: left;
  display: block;
  padding: 0px;
  z-index: 0;
}
h3.error {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
div.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 40px);
  width: 100vw;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
div.data-error h1 {
  margin-bottom: 20px;
}
p.loading-text {
  margin-top: 20px;
  text-align: center;
}
p.tos {
  margin-top: 60px;
  max-width: 400px;
  text-align: center;
  color: var(--grey3);
}
p.tos a {
  font-weight: 600;
}
