.detail-item img {
    width: 18px;
    display: inline-block;
    margin-right: 10px;
}
.detail-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.schedule-grid {
    max-width: 800px;
    margin: auto !important;
    margin-top: 60px !important;
    border: 1px solid var(--grey6);
    border-radius: 10px;
}
.schedule-grid.selected {
    max-width: 1000px;
}
.schedule-details {
    height: 560px;
    padding: 20px;
    border-right: 1px solid var(--grey6);
    overflow: scroll;
}
.detail-item p {
    word-break: break-word;
}
.schedule-details .artist-header, .details-details .artist-header {
    justify-content: flex-start;
    border-bottom: 1px solid var(--grey6);
    padding-top: 20px;
}
.details-stack {
    margin: 20px 0px;
    max-height: 361px;
    overflow: scroll;
}
.calendar-box {
    margin-top: 40px;
}
.schedule-box {
    padding: 20px;
}
.calendar-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 40px;
  margin-bottom: 20px;
}
.calendar-toggles {
    display: flex;
    position: absolute;
    right: 0;
}
.calendar-days h4 {
    text-align: center;
}
.calendar-days {
    border-bottom: 1px solid var(--grey6);
    padding-bottom: 10px;
}
.calendar-rows {
    margin: 20px 0px;
}
.calendar-row .hidden p {
    display: none;
}
.calendar-row p {
    text-align: center;
    color: var(--grey4 );
}
div.day {
    height: 52px;
    align-items: center;
    justify-content: center;
    display: flex;
}
div.day.available {
    border-radius: 26px;
    background: #FFD4CC;
}
div.day.available p {
    font-weight: 600;
    color: var(--red);
}
.time-box.hidden {
    display: none;
}
.time-box {
    margin-top: 40px;
    max-height: 502px;
    overflow: scroll;
}
.time-item {
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    border: 1px solid var(--red);
    border-radius: 5px;
}
.time-stack {
    margin-top: 20px;
}
div.schedule-box div.back {
  display: none;
}
.time-item h3 {
    color: var(--red);
}
.schedule-grid-item {
    height: 600px;
}
.schedule-box {
    height: 560px;
}
.review-container.confirm {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(90deg, var(--grey6), var(--grey6) 50%, #fff 50%, #fff);
    margin-bottom: 0px;
    margin-top: 0px;
}
.appointment-details, .payment-details, .appointment-waiver {
    padding: 80px 20px 20px 20px;
}
.back img {
    width: 24px;
    height: 24px;
}
.back {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    background: var(--red);
    margin-bottom: 40px;
}
div.appointment-details .back {
  position: relative;
}
.details-header {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--grey6);
    position: sticky;
}
div.back svg {
    color: #fff;
}
.review-stack {
    padding-top: 20px;
    padding-bottom: 20px;
}
.payment-row {
    display: flex;
    justify-content: space-between;
}
.payment-row.payment-total {
    padding-top: 10px;
    border-top: 1px solid #000;
}
.payment-stack {
    margin-bottom: 20px;
}
div.back a {
    display: flex;
}
div.review-left {
    max-height: 95vh;
    overflow: scroll;
    margin-bottom: 5vh;
}
.booking-login form {
    margin-top: 40px;
}
form.code-form h2 {
    margin-bottom: 0px;
}
form.code-form p {
    margin-bottom: 60px;
    text-align: center;
}
.waiver-checkbox {
  width: 100%;
}
div.waiver {
  margin-top: 40px;
  padding: 20px;
  max-height: 50vh;
  overflow: scroll;
  text-align: justify;
  color: #000;
}
.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
div.schedule-grid-item.action {
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
div.schedule-grid-item .artist-header, div.appointment-details .artist-header {
  justify-content: flex-start;
  border-bottom: 1px solid var(--grey6);
  padding: 40px 0px 20px 0px;
  margin-bottom: 20px;
}
div.schedule-grid-item div.artist-header-details h1 {
  color: var(--grey1);
}
.appointment-details .schedule-details {
  height: auto;
  overflow: visible;
  border-right: none;
}
.payment-total p {
  font-weight: 600;
}
@media(max-width: 1120px) {
  div.MuiContainer-maxWidthLg {
    padding: 20px;
  }
}
@media(max-width: 900px) {
    div.schedule-grid {
        border: none;
        border-radius: 0px;
        margin-top: 0px !important;
    }
    .schedule-details h1 {
        text-align: center;
    }
    div.schedule-grid-item .artist-header, div.appointment-details .artist-header {
      justify-content: center;
      border-bottom: none;
      padding: 20px;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    div.calendar-box {
      max-width: 367px;
      margin: auto;
  }
    div.schedule-grid-item .artist-header h1, div.appointment-details .artist-header h1 {
      text-align: left;
    }
    .schedule-details .artist-header h1, .appointment-details .artist-header h1 {
        text-align: left;
    }
    .schedule-details .artist-header, .appointment-details .artist-header {
        justify-content: center;
        margin-left: 24px;
        margin-right: 24px;
    }
    .schedule-details {
        padding: 20px 0px;
        border-right: none;
        height: auto;
        overflow: visible;
    }
    .schedule-grid-item {
        height: auto;
    }
    .details-stack {
        display: block;
        margin-top: 20px;
        margin-left: 24px;
        margin-right: 24px;
    }
    .schedule-box h3 {
        text-align: center;
    }
    .calendar-box.hidden {
        display: none;
    }
    .time-box p {
        text-align: center;
    }
    .review-container.confirm {
        background: #fff;
        box-shadow: none;
        height: auto;
    }
    .appointment-details h1 {
        text-align: center;
    }
    .payment-details {
        padding-top: 0px;
    }
    .review-stack h3, .review-stack p {
        text-align: center;
    }
    a.review-link {
        position: relative;
        top: 5px;
    }
    div.review-left {
        max-height: none;
        margin-bottom: 0px;
    }
    .hidden-sm {
        display: none;
    }
    .schedule-box {
        padding-left: 0px;
        padding-right: 0px;
        border-top: 1px solid var(--grey6);
    }
    .appointment-details {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .appointment-waiver {
      padding-top: 0px;
    }
    div.waiver {
      margin-top: 5px;
    }
    .action-buttons {
      align-items: flex-start;
    }
    div.schedule-box div.back {
      display: flex;
    }
}
div.day.available.hidden {
    background: none;
}
div#payment-element {
  padding: 20px;
}
