img.artist-thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: inline-block;
}
div.artist-header-details {
    display: inline-block;
    margin-left: 10px;
}
.artist-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 20px;
}
.progress-bar {
    margin-top: 60px;
    margin-bottom: 60px;
}
.step-circle {
    height: 32px;
    width: 32px;
    color: var(--grey4);
    outline: 1px solid var(--grey4);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background-color: #fff;
    margin: auto;
}
.step-circle.done {
    color: #FFF;
    outline: none;
    background-color: var(--red);
}
.step-circle h2 {
    color: var(--grey4);
}
.step-circle.done h2 {
    color: #FFF;
}
.step-line.done {
    background-color: var(--red);
}
.step-label p {
    text-align: center;
    margin-top: 10px;
    color: var(--grey4);
}
.progress-bar {
    position: relative;
}
.progress-line {
    position: absolute;
    top: 14px;
    width: 100%;
    height: 4px;
    background-color: var(--grey4);
    z-index: 0;
}
.step {
    z-index: 1;
}
div.artist-header-details h1 {
    margin-bottom: 5px;
}
.step-label {
  text-transform: capitalize;
}
@media(max-width: 900px) {
    div.progress-bar {
        display: none;
    }
}
