h2.form-header {
    text-align: center;
    max-width: 700px;
    display: block;
    margin: auto;
    margin-bottom: 60px;
}
button.next {
    display: block;
    margin: auto;
    margin-top: 60px;
    padding: 0px 60px;
    height: 48px;
    background-color: var(--grey6);
    box-shadow: none;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
}
button.next:hover {
    background-color: var(--grey6);
    box-shadow: none;
}
button.next.done {
    background-color: var(--red);
    box-shadow: 0 10px 40px -10px rgb(255 48 8 / 50%);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -moz-transition: all .2s,-moz-transform .5s;
    -o-transition: all .2s,-o-transform .5s;
    -webkit-transition: all .2s,-webkit-transform .5s;
    transition: all .2s,transform .5s;
}
button.next.done.MuiLoadingButton-root	{
    box-shadow: 0 10px 40px -10px rgb(255 48 8 / 50%);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -moz-transition: all .2s,-moz-transform .5s;
    -o-transition: all .2s,-o-transform .5s;
    -webkit-transition: all .2s,-webkit-transform .5s;
    transition: all .2s,transform .5s;
}
button.next.done:hover {
    background-color: #c30000;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
svg.form-back {
    position: absolute;
    top: 0;
    left: 0;
}
.name-input-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.MuiInputBase-root {
  border-radius: 10px;
}
fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--grey6);
}
div.MuiSelect-select {
    border: 1px solid var(--grey6);
}
label.MuiFormLabel-root {
    color: var(--grey4);
}
div.MuiInputAdornment-outlined {
  padding-top: 12px;
}
div.textarea {
  width: 400px;
}
div#location, div#skinTone {
    min-width: 254px;
    border: none;
}
div.radio-box {
    display: flex;
    justify-content: center;
    align-items: center;
}
label.radio-pill {
    width: 300px;
    height: 68px;
    background: var(--grey6);
    margin: auto;
    margin-bottom: 10px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
}
label.radio-pill.checked {
    background: var(--red);
}
span.radio-input {
    display: none;
}
label.radio-pill.checked span {
    color: #fff;
}
img.size-icon {
    width: 64px;
    display: block;
    margin: auto;
}
div.size-label h3 {
    text-align: center;
    margin-top: 20px;
}
div.size-label p {
    text-align: center;
    max-width: 95%;
    display: block;
    margin: auto;
    margin-bottom: 11px;
}
label.size-label {
    margin: 0px 10px 0px 10px;
}
label.size-label.xs {
    margin-left: 0px;
    margin-right: 15px;
}
label.size-label.sm {
    margin-left: 5px;
}
label.size-label.md {
    margin-right: 5px;
}
label.size-label.lg {
    margin-left: 15px;
    margin-right: 0px;
}
div.review-section {
    max-width: 510px;
    display: block;
    margin: auto;
}
.back {
  position: absolute;
  z-index: 1300;
}
button.review-link {
    position: absolute;
    top: 0;
    right: 0;
}
.review-item {
    position: relative;
}
.review-container {
    margin-bottom: 120px;
}
form {
    position: relative;
}
.input-reg label.Mui-focused {
    color: var(--grey4);
}
div.input-reg .MuiInputLabel-root {
    line-height: 60px;
    top: -15px;
}
div.input-reg div.Mui-focused fieldset {
    border-color: var(--grey6) !important;
}
div.input-reg .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--grey6) !important;
}
.input-reg .MuiOutlinedInput-notchedOutline {
    border-color: var(--grey6) !important;
    border-width: 1px !important;
    outline: 0 !important;
    border: none;
}
div.input-reg .MuiInputLabel-root.MuiInputLabel-shrink {
    top: 20px !important;
    line-height: 18px !important;
}
div.input-reg .MuiOutlinedInput-root {
    border-radius: 10px;
    border: 1px solid var(--grey6);
}
.other-details-div {
    margin: auto;
    max-width: 400px;
}
div.other-details-div .input-reg input {
    width: 370px;
}
div.review-container {
    position: relative;
}
div.imagePreviews img {
    width: 265px;
    height: 265px;
    object-fit: cover;
    border-radius: 20px;
}
div.image-input input {
    display: none;
}
label.image-label {
    height: 265px;
    width: 265px;
    border: 1px dotted #000;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.upload-icon svg {
    margin: auto;
    display: block;
    margin-bottom: 10px;
}
form {
    margin-bottom: 60px;
}
div.review-item img.image-upload {
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 20px;
}
div.phone-input {
    width: 300px;
    display: block;
    margin: auto;
}
.react-tel-input .input-reg {
    width: 300px !important;
    border: 1px solid var(--grey6) !important;
    height: 60px !important;
    border-radius: 10px !important;
    font-size: 1.125rem !important;
    font-weight: 400 !important;
    color: #333333 !important;
    font-family: "Montserrat", "Open Sans", "Helvetica", "Arial", "sans-serif" !important;
}
p.error {
    text-align: center;
    margin-top: 20px;
    color: var(--red);
}
input.input-code {
    border: 1px solid var(--grey6);
    height: 60px;
    border-radius: 10px;
    font-size: 1.125rem;
    font-weight: 600;
    color: #333333;
    font-family: "Montserrat", "Open Sans", "Helvetica", "Arial", "sans-serif";
    width: 32px;
    margin: 0px 5px;
    text-align: center;
}
h2.form-header.sub {
    margin-bottom: 0px;
}
p.sub {
    text-align: center;
    margin-bottom: 60px;
}
.review-container {
    margin-top: 40px;
}
div.MuiInputAdornment-outlined {
    line-height: 22px;
    padding-top: 27px;
    height: 22px;
    padding-bottom: 9px;
}
.review-section a {
    position: absolute !important;
    top: 0 !important;
}
div.multi-input-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
  margin: auto;
}
@media(max-width: 600px) {
    h2.form-header {
        max-width: 90%;
        display: block;
        margin: auto;
        margin-bottom: 40px;
    }
    button.next {
      margin-top: 40px;
    }
    .input-reg {
        max-width: 90%;
    }
    label.size-label {
        margin-bottom: 20px;
    }
    div.imagePreviews {
        justify-content: center;
    }
    div.review-section {
        max-width: 90%;
    }
    form {
        padding-top: 0px;
    }
    label.size-label {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    div.name-input-container {
      flex-direction: column;
      max-width: 300px;
      margin: auto;
    }
}
div.budget {
    width: fit-content;
    display: block;
    margin: auto;
}
@media(max-width : 900px) {
  .back {
    margin-bottom: 0px;
    margin: auto;
    margin-top: 20px;
    position: relative;
  }
  div.textarea {
    max-width: 90%;
  }
  input.input-code {
    width: 26px;
  }
}
@media(min-width: 600px) {
    h2.form-header {
        line-height: 36px;
    }
    div.input-reg.other-notes {
        max-width: 100%;
    }
}
