.shop-header {
  display: block;
  padding-top: 40px;
  padding-bottom: 20px;
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 1;
}
.shop-header .thumbnail-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
img.shop-thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  display: inline-block;
}
div.shop-header-details {
  display: inline-block;
  margin-left: 10px;
}
div.shop-header-details h1 {
  margin-bottom: 5px;
}
div.shop-header-details h1, div.shop-header-details p {
  text-align: left;
}
img.shop-cover {
  display: none;
}
h2.shop-header {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}
@media(min-width: 900px) {
  img.shop-cover {
    width: 100vw;
    height: 400px;
    display: block;
    margin: auto;
    top: 0;
    visibility: visible;
    opacity: 1;
  }
  img.shop-cover.scroll {
    visibility: hidden;
    opacity: 0;
    transition: visibility .3s, opacity .3s linear;
    display: none;
  }
  img.shop-thumbnail {
    width: 190px;
    height: 190px;
    border-radius: 100px;
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }
  img.shop-thumbnail.scroll {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: inline-block;
    margin: 0px;
    transition: width .5s, height .5s, border-radius .5s linear;
  }
  div.shop-header-details h1, div.shop-header-details p {
    text-align: center;
  }
  div.shop-header-details.scroll h1, div.shop-header-details.scroll p {
    text-align: left;
    transition: text-align 1s linear;
  }
  .shop-header {
    display: block;
    margin-top: -135px;
    position: relative;
    z-index: 0;
    background: none;
  }
  .shop-header .thumbnail-header {
    display: block;
  }
  .shop-header.scroll {
    padding-top: 40px;
    padding-bottom: 20px;
    position: fixed;
    width: 100%;
    background: #fff;
    top: 135px;
    z-index: 1;
  }
  .shop-header.scroll .thumbnail-header {
      display: flex;
      justify-content: center;
      align-items: center;
  }
}
